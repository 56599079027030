import { render, staticRenderFns } from "./BrandsProducts.vue?vue&type=template&id=47429927&scoped=true&"
import script from "./BrandsProducts.vue?vue&type=script&lang=js&"
export * from "./BrandsProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47429927",
  null
  
)

export default component.exports