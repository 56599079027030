<template>
  <div>
    <v-card class="shadow rounded-lg">
      <v-progress-linear :value="progress" color="primary" height="7" striped></v-progress-linear>

      <Tabs :step.sync="stepVal"/>

      <v-card-text class="pt-10 px-15">

        <v-card outlined class="rounded-lg mb-5">
          <v-card-title class="grey lighten-5">
            <span class="fs-15">Produits concernés</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            Marques *
            <div v-if="isLoading" class="d-flex mb-3">
              <v-skeleton-loader v-for="item in 5" :key="item" class="mx-1" type="chip"/>
            </div>

            <v-chip-group v-else
                          v-model="form.brands"
                          class="mb-3"
                          color="primary"
                          column
                          multiple
            >
              <v-chip v-for="(brand,i) in brands"
                      :key="i"
                      :value="brand.id"
                      color="primary"
                      filter
              >
                {{ brand.name }}
              </v-chip>
            </v-chip-group>

            <v-autocomplete v-model="form.products"
                            :disabled="isLoading"
                            :items="sortedListProducts"
                            :loading="isLoading"
                            chips
                            class="autocomplete"
                            deletable-chips
                            dense
                            item-text="name"
                            item-value="id"
                            label="Produits *"
                            multiple
                            outlined
                            small-chips
            >
            </v-autocomplete>

            <v-text-field v-model="form.min_product_qty"
                          v-number
                          dense
                          label="Quantité minimale de produit pour gagner un cadeau *"
                          min="1"
                          outlined type="number"/>
          </v-card-text>
        </v-card>

        <v-card outlined class="rounded-lg">
          <v-card-title class="grey lighten-5">
            <span class="fs-15">Produits secondaires</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            Marques
            <div v-if="isLoading" class="d-flex mb-3">
              <v-skeleton-loader v-for="item in 5" :key="item" class="mx-1" type="chip"/>
            </div>

            <v-chip-group v-else
                          v-model="form.secondary_brands"
                          class="mb-3"
                          color="primary"
                          column
                          multiple
            >
              <v-chip v-for="(brand,i) in brands"
                      :key="i"
                      :value="brand.id"
                      color="primary"
                      filter
              >
                {{ brand.name }}
              </v-chip>
            </v-chip-group>

            <v-autocomplete v-model="form.secondary_products"
                            :disabled="isLoading"
                            :items="sortedListSecondaryProducts"
                            :loading="isLoading"
                            chips
                            class="autocomplete"
                            deletable-chips
                            dense
                            item-text="name"
                            item-value="id"
                            label="Produits"
                            multiple
                            outlined
                            small-chips
            >
            </v-autocomplete>

            <v-text-field v-model="form.secondary_min_product_qty"
                          v-number
                          dense
                          label="Quantité minimale de produit pour gagner un cadeau"
                          min="1"
                          outlined type="number"/>
          </v-card-text>
        </v-card>

      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn color="primary"
               text
               @click="$emit('back')"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Retour
        </v-btn>
        <v-spacer/>
        <v-btn color="secondary"
               depressed
               @click="$emit('next')">
          Suivant
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>

    </v-card>
  </div>
</template>

<script>
import Tabs from "@/views/campaigns/create/components/Tabs.vue";
import axios from "axios";
import {HTTP} from "@/http-common";

export default {
  props: {
    step: {
      type: Number
    },
    form: Object
  },
  components: {Tabs},
  data() {
    return {
      progress: 25,
      isLoading: false,
      brands: [],
      products: [],
    }
  },
  methods: {
    getProductsAndBrands() {
      this.isLoading = true
      axios.all([
        HTTP.get('/brands'),
        HTTP.get('/products')
      ]).then(axios.spread((res1, res2) => {
        this.brands = res1.data.data
        this.products = res2.data.data
        this.isLoading = false
      })).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  created() {
    this.getProductsAndBrands()
  },
  mounted() {
    this.$vuetify.goTo(0)
    let _vm = this
    setTimeout(function () {
      _vm.progress += 25
    }, 500)
  },
  computed: {
    stepVal: {
      get: function () {
        return this.step;
      },
      set: function (newValue) {
        this.$emit('update:step', newValue)
      }
    },
    sortedListProducts() {
      if (this.form.brands.length) {
        let brandIds = this.form.brands
        return this.products.filter(item => brandIds.includes(item.brand_id))
      } else {
        return []
      }
    },
    sortedListSecondaryProducts() {
      if (this.form.secondary_brands.length) {
        let brandIds = this.form.secondary_brands
        return this.products.filter(item => brandIds.includes(item.brand_id))
      } else {
        return []
      }
    },
  }
}
</script>

<style scoped>

</style>