<template>
  <div>
    <v-card class="shadow rounded-lg">
      <v-progress-linear :value="progress" color="primary" height="7" striped></v-progress-linear>

      <Tabs :step.sync="stepVal"/>

      <v-card-text class="pt-10 px-15">

        <v-skeleton-loader v-if="isLoading"
                           type="list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar"/>

        <v-simple-table v-if="!isLoading"
                        class="rounded-lg table-border">
          <template v-slot:default>

            <thead>
            <tr>
              <th></th>
              <th>Nom</th>
              <th>Téléphone</th>
              <th>E-mail</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item,index) in form.animators" :key="index"
                :class="item.is_active ? 'v-data-table__selected' : ''">

              <td>
                <v-checkbox v-model="item.is_active"
                            :false-value="false"
                            :true-value="true"
                            class="pa-0 ma-0"
                            color="secondary"
                            hide-details
                />
              </td>

              <td>
                <div class="d-flex text-no-wrap align-center  text-truncate">

                  <v-avatar v-if="item.photo" size="38">
                    <v-img :src="$baseUrl + item.photo"
                           class="zoom-pointer"
                           @click="$refs.lightbox.open(item.photo)"></v-img>
                  </v-avatar>

                  <v-avatar v-else
                            :class="$func.randomColor()"
                            class="font-weight-medium"
                            size="38">
                    {{ $func.avatarName(item.name) }}
                  </v-avatar>

                  <span class="ml-2"> {{ item.name }}</span>
                </div>
              </td>

              <td>
                {{ item.phone }}
              </td>

              <td>
                <a :href="'mailto:'+item.email"
                   class="d-block orange--text text-decoration-none">
                  {{ item.email }}
                </a>
              </td>

            </tr>

            <tr v-if="!isLoading && !form.animators.length">
              <td colspan="4" class="text-center">Aucun enregistrement trouvé!</td>
            </tr>

            </tbody>
          </template>

        </v-simple-table>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn color="primary"
               text
               @click="$emit('back')"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Retour
        </v-btn>
        <v-spacer/>
        <v-btn color="secondary"
               depressed
               @click="$emit('next')">
          Suivant
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>

    </v-card>

    <Lightbox ref="lightbox"/>

  </div>
</template>

<script>
import Tabs from "@/views/campaigns/create/components/Tabs.vue";
import {HTTP} from "@/http-common";

export default {
  props: {
    step: {
      type: Number
    },
    form: Object
  },
  components: {Tabs},
  data() {
    return {
      isLoading: false,
      progress: 50,
    }
  },
  methods: {
    getAnimators() {
      this.isLoading = true
      HTTP.get('/campaigns/animators').then((res) => {
        this.isLoading = false
        /**
         * Spin To Win
         * @type {any}
         */
        this.form.animators = JSON.parse(JSON.stringify(res.data.data.map(el => {

          let index = this.form.animators.findIndex(item => item.id === el.id)
          let data = this.form.animators[index]
          el['is_active'] = data ? data['is_active'] : false
          return el
        })))

      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },

  },
  created() {
    this.getAnimators()
  },
  mounted() {
    this.$vuetify.goTo(0)
    let _vm = this
    setTimeout(function () {
      _vm.progress += 25
    }, 500)
  },
  computed: {
    stepVal: {
      get: function () {
        return this.step;
      },
      set: function (newValue) {
        this.$emit('update:step', newValue)
      }
    },
  }
}
</script>

<style>

</style>