<template>
  <div>
    <v-card class="shadow rounded-lg">
      <v-progress-linear :value="progress" color="primary" height="7" striped></v-progress-linear>

      <Tabs :step.sync="stepVal"/>

      <v-card-text class="pt-10 px-15">

        <v-card outlined class="rounded-lg mb-5">
          <v-card-title class="grey lighten-5">
            <span class="fs-15">Cadeaux pour les produits concernés</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-skeleton-loader v-if="isLoading"
                               type="list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar"/>

            <v-simple-table v-if="!isLoading" fixed-header
                            class="rounded-lg table-border" height="300">
              <template v-slot:default>
                <thead>
                <tr>
                  <th></th>
                  <th>Cadeau</th>
                  <th>Pourcentage & Montant & Destinataire</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,index) in form.gifts" :key="index"
                    :class="item.is_active ? 'v-data-table__selected' : ''">
                  <td>
                    <v-checkbox v-model="item.is_active"
                                :false-value="false"
                                :true-value="true"
                                class="pa-0 ma-0"
                                color="secondary"
                                hide-details
                    />
                  </td>
                  <td>
                    <div class="d-flex align-center py-3">
                      <v-img :src="$baseUrl + item.image"
                             class="rounded zoom-pointer"
                             contain
                             max-height="40"
                             max-width="40"
                             min-height="40"
                             min-width="40"
                             @click="$refs.lightbox.open(item.image)"></v-img>
                      <span class="ml-2">{{ item.name }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <v-text-field v-if="item.is_digital"
                                    v-model="item.digital_amount"
                                    v-number
                                    dense
                                    hide-details
                                    outlined
                                    placeholder="Montant *"
                                    suffix="DZD"/>
                      <span v-if="item.is_digital"> &nbsp;</span>
                      <v-select v-if="item.is_digital"
                                v-model="item.recipient_digital_amount"
                                :items="recipients"
                                dense
                                hide-details
                                item-text="name"
                                item-value="value"
                                outlined
                                placeholder="Destinataire *"
                      />
                      <span v-if="item.is_digital"> &nbsp;</span>
                      <v-text-field v-model="item.percentage"
                                    v-number
                                    dense
                                    hide-details
                                    outlined
                                    placeholder="Pourcentage *"
                                    suffix="%"/>
                    </div>
                  </td>
                </tr>

                <tr v-if="!isLoading && !form.gifts.length">
                  <td colspan="3" class="text-center">Aucun enregistrement trouvé!</td>
                </tr>

                </tbody>
              </template>

            </v-simple-table>
          </v-card-text>
        </v-card>

        <v-card outlined class="rounded-lg">
          <v-card-title class="grey lighten-5">
            <span class="fs-15">Cadeaux pour les produits secondaires</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-skeleton-loader v-if="isLoading"
                               type="list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar"/>

            <v-simple-table v-if="!isLoading"
                            class="rounded-lg table-border" height="300" fixed-header>
              <template v-slot:default>
                <thead>
                <tr>
                  <th></th>
                  <th>Cadeau</th>
                  <th>Montant & Destinataire</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,index) in form.secondary_gifts" :key="index"
                    :class="item.is_active ? 'v-data-table__selected' : ''">
                  <td>
                    <v-checkbox v-model="item.is_active"
                                :false-value="false"
                                :true-value="true"
                                class="pa-0 ma-0"
                                color="secondary"
                                hide-details
                    />
                  </td>
                  <td>
                    <div class="d-flex align-center py-3">
                      <v-img :src="$baseUrl + item.image"
                             class="rounded zoom-pointer"
                             contain
                             max-height="40"
                             max-width="40"
                             min-height="40"
                             min-width="40"
                             @click="$refs.lightbox.open(item.image)"></v-img>
                      <span class="ml-2">{{ item.name }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <v-text-field v-if="item.is_digital"
                                    v-model="item.digital_amount"
                                    v-number
                                    dense
                                    hide-details
                                    outlined
                                    placeholder="Montant *"
                                    suffix="DZD"/>
                      <span v-if="item.is_digital"> &nbsp;</span>
                      <v-select v-if="item.is_digital"
                                v-model="item.recipient_digital_amount"
                                :items="recipients"
                                dense
                                hide-details
                                item-text="name"
                                item-value="value"
                                outlined
                                placeholder="Destinataire *"
                      />
                    </div>
                  </td>
                </tr>

                <tr v-if="!isLoading && !form.secondary_gifts.length">
                  <td colspan="3" class="text-center">Aucun enregistrement trouvé!</td>
                </tr>

                </tbody>
              </template>

            </v-simple-table>
          </v-card-text>
        </v-card>

      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn color="primary"
               text
               @click="$emit('back')"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Retour
        </v-btn>
        <v-spacer/>
        <v-btn color="secondary"
               depressed
               @click="$emit('save')">
          <v-icon left>mdi-content-save</v-icon>
          Enregistrer

        </v-btn>
      </v-card-actions>

    </v-card>

    <Lightbox ref="lightbox"/>

  </div>
</template>

<script>
import Tabs from "@/views/campaigns/create/components/Tabs.vue";
import {HTTP} from "@/http-common";

export default {
  props: {
    step: {
      type: Number
    },
    form: Object
  },
  components: {Tabs},
  data() {
    return {
      isLoading: false,
      progress: 75,
      recipients: [
        {name: 'Consommateur', value: 'consumer'},
        {name: 'Point de vente', value: 'salepoint'},
        {name: 'Animateur', value: 'animator'},
      ],
    }
  },
  methods: {
    getGifts() {
      this.isLoading = true
      HTTP.get('/gifts').then((res) => {
        this.isLoading = false

        /**
         * Spin To Win
         * @type {any}
         */
        this.form.gifts = JSON.parse(JSON.stringify(res.data.data.map(el => {

          let index = this.form.gifts.findIndex(item => item.id === el.id)
          let data = this.form.gifts[index]

          el['percentage'] = data ? data['percentage'] : null
          el['digital_amount'] = data ? data['digital_amount'] : null
          el['recipient_digital_amount'] = data ? data['recipient_digital_amount'] : null
          el['is_active'] = data ? data['is_active'] : false
          return el
        })))

        /**
         * Secondary gifts
         * @type {any}
         */
        this.form.secondary_gifts = JSON.parse(JSON.stringify(res.data.data.map(el => {

          let index = this.form.secondary_gifts.findIndex(item => item.id === el.id)
          let data = this.form.secondary_gifts[index]

          el['digital_amount'] = data ? data['digital_amount'] : null
          el['recipient_digital_amount'] = data ? data['recipient_digital_amount'] : null
          el['is_active'] = data ? data['is_active'] : false
          return el
        })))

      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },

  },
  created() {
    this.getGifts()
  },
  mounted() {
    this.$vuetify.goTo(0)
    let _vm = this
    setTimeout(function () {
      _vm.progress += 25
    }, 500)
  },
  computed: {
    stepVal: {
      get: function () {
        return this.step;
      },
      set: function (newValue) {
        this.$emit('update:step', newValue)
      }
    },
  }
}
</script>

<style>

</style>