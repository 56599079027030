<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" lg="10">

          <v-alert v-if="Object.keys(errors).length" border="top" dismissible prominent type="error">
            <ul class="fs-15">
              <li v-for="(err,index) in errors" :key="index">
                {{ err.toString() }}
              </li>
            </ul>
          </v-alert>

          <Overlay :overlay="isLoading"/>

          <InfoPlanning v-if="step === 0"
                        :form.sync="form"
                        :step.sync="step"
                        @next="step = 1"/>

          <BrandsProducts v-if="step === 1"
                          :form.sync="form"
                          :step.sync="step"
                          @back="step = 0"
                          @next="step = 2"/>

          <Animators v-if="step === 2"
                     :form.sync="form"
                     :step.sync="step"
                     @back="step = 1"
                     @next="step = 3"/>

          <Gifts v-if="step === 3"
                 :form.sync="form"
                 :step.sync="step"
                 @back="step = 2"
                 @save="save"/>


          <ConfirmDialog ref="confirmDialog"/>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import InfoPlanning from "@/views/campaigns/create/components/InfoPlanning.vue";
import Gifts from "@/views/campaigns/create/components/Gifts.vue";
import BrandsProducts from "@/views/campaigns/create/components/BrandsProducts.vue";
import {HTTP} from "@/http-common";
import Animators from "@/views/campaigns/create/components/Animators.vue";

export default {
  components: {Animators, InfoPlanning, Gifts, BrandsProducts},
  data() {
    return {
      isLoading: false,
      errors: {},
      step: 0,
      form: {
        id: null,
        name: null,
        start_date: null,
        end_date: null,
        description: null,
        color: '#23b7e5',
        image: null,

        min_product_qty: 1,
        secondary_min_product_qty: 1,

        products: [],
        secondary_products: [],

        brands: [],
        secondary_brands: [],

        gifts: [],
        secondary_gifts: [],

        animators: [],
      }
    }
  },
  methods: {
    async save() {
      if (await this.$refs.confirmDialog.open('', 'Êtes-vous sûr de vouloir enregistrer cette campagne ?', {
        color: 'orange',
        agreeText: 'Enregistrer',
        icon: 'mdi-information-outline',
      })) {
        this.isLoading = true
        this.errors = {}
        let url = this.form.id ? "/campaigns/update" : "/campaigns/store"

        HTTP.post(url, this.form).then(() => {
          this.$successMessage = "Cette campagne a été enregistré avec succés"
          this.isLoading = false
          this.$router.push('/campaigns')
        }).catch(err => {
          console.log(err)
          this.isLoading = false
          this.$vuetify.goTo(0)
          this.errors = err.response.data.errors
          this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        })
      }
    },
    showCampaign() {
      this.isLoading = true
      HTTP.get('/campaigns/' + this.$route.params.id + '/show').then((res) => {
        this.isLoading = false
        if (res && res.data && !res.data.data) {
          this.$router.push('/404')
        } else {
          let campaign = res.data.data

          this.form = {
            id: campaign.id,
            name: campaign.name,
            start_date: campaign.start_date,
            end_date: campaign.end_date,
            description: campaign.description,
            color: campaign.color,
            image: campaign.image,

            min_product_qty: campaign.min_product_qty,
            secondary_min_product_qty: campaign.secondary_min_product_qty,

            products: campaign.products.map(el => el.id),
            secondary_products: campaign.secondary_products.map(el => el.id),

            brands: campaign.brands.map(el => el.id),
            secondary_brands: campaign.secondary_brands.map(el => el.id),

            gifts: campaign.gifts.map(el => {
              return {
                id: el.id,
                is_digital: el.is_digital,
                name: el.name,
                image: el.image,
                type: el.type,
                is_active: true,

                percentage: el.pivot.percentage,
                digital_amount: el.pivot.digital_amount,
                recipient_digital_amount: el.pivot.recipient_digital_amount,
              }
            }),

            secondary_gifts: campaign.secondary_gifts.map(el => {
              return {
                id: el.id,
                is_digital: el.is_digital,
                name: el.name,
                image: el.image,
                type: el.type,
                is_active: true,
                digital_amount: el.pivot.digital_amount,
                recipient_digital_amount: el.pivot.recipient_digital_amount,
              }
            }),

            animators: campaign.animators.map(el => {
              el.is_active = true
              return el
            }),
          }
        }

      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  mounted() {
    if (this.$route.params.id && this.$route.name === 'campaigns.update') {
      this.showCampaign()
    }
  }
}
</script>

<style scoped>

</style>